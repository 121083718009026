import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Link, Stack, Typography } from '@mui/joy';
import { useOutletContext } from 'react-router-dom';
import { SVGExternalLink } from 'icons';
import { PageHeader } from 'elements/PageHeader';
import { useLocale } from 'hooks';
import { MainWrapper } from 'elements/MainWrapper';
import { DemographicSelector } from './DemographicSelector';

const MAX_OPTIONS_FOR_PILLS = 25;

export const DemographicsSelectionPage = (props) => {
  const {
    demographics,
    selected_demographics,
    errors,
    setDemographicSelection,
    toggleDemographicSelection,
    setErrors
  } = props;

  const {
    onStartSurvey,
    min_responses_for_report
  } = useOutletContext();

  const [loading, setLoading] = useState();

  const [locale] = useLocale();

  const { t } = useTranslation(['demographics_page', 'translation']);

  const onClick = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      setErrors(undefined);
      const allDemographics = demographics.object(({ name: { en } }) => (
        {
          [en]: selected_demographics?.[en] ? selected_demographics[en] : []
        }
      ));

      onStartSurvey({ demographics: allDemographics })
        .then(() => {
          setLoading(false);
        })
        .catch(({ errors: { demographics: demographic_errors } = {} }) => {
          setErrors(demographic_errors);
          setLoading(false);
        });
    },
    [onStartSurvey, selected_demographics, demographics, setErrors]
  );

  const tooManyDemographicsForPills = useMemo(
    () => demographics.reduce((maxCount, { options }) => Math.max(maxCount || 0, options.length), 0) > MAX_OPTIONS_FOR_PILLS,
    [demographics]
  );

  const below_min_responses_for_report = min_responses_for_report <= 1;

  const formattedErrors = useMemo(
    () => {
      return Object.entries(errors || {}).reduce(
        (formatted, [key, value]) => {
          let error = value;
          if (Array.isArray(value) && value.length > 0) {
            error = value[0];
          }
          if (typeof error === 'object') {
            error = error[locale] || error.en;
          }

          formatted[key] = error;
          return formatted;
        },
        {}
      );
    },
    [errors, locale]
  );

  return (
    <>
      <PageHeader hideSaveOrExit />
      <MainWrapper style={{ paddingTop: 40 }}>
        <Stack
          alignItems={'center'}
          spacing={{ xs: 5, md: 8 }}
        >
          <section style={{ maxWidth: '540px' }}>
            <Typography
              sx={{ textAlign: 'center' }}
              level={'h1'}
              fontSize={{ xs: 40, md: 48 }}
              data-cy={'demographics-title'}
              mb={{ xs: 4, md: 3 }}
            >
              {t('Tell us about yourself')}
            </Typography>

            {below_min_responses_for_report ? (
              <Typography sx={{ textAlign: 'center' }}>
                {t('We use this information to help us better understand the survey results.')}
              </Typography>
            ) : (
              <Typography
                sx={{ textAlign: 'center' }}
                data-cy={'demographics-page-message-anonymous'}
              >
                {t("We group this information with similar participants to analyse results. It can't be used to identify you or the answers you provide. Learn more about")}
                {' '}
                <Link
                  underline={'always'}
                  href={'https://stonly.com/sl/en/9c53d01c-ad23-45c2-9433-925989182efe/Steps/1815296'}
                  target={'_blank'} rel={'noreferrer'}
                >
                  {t('how demographic data is used')}
                  <SVGExternalLink
                    style={{ marginLeft: 8 }}
                    size={12}
                  />
                </Link>
              </Typography>
            )}
          </section>
          <form style={{ width: '100%' }} onSubmit={onClick}>
            <Stack
              alignItems={'center'}
              spacing={{ xs: 5 }}
            >
              {demographics.map(
                ({ id, name, options, survey_label, multi_select_options_limit, disabled = false }) => {
                  const { en: demographicKey } = name;
                  const error = formattedErrors[demographicKey];

                  return (
                    <DemographicSelector
                      key={id}
                      demographicId={parseInt(id)}
                      values={selected_demographics}
                      demographicKey={demographicKey}
                      name={name}
                      options={options}
                      survey_label={survey_label}
                      multi_select_options_limit={multi_select_options_limit}
                      useSelectBox={tooManyDemographicsForPills}
                      disabled={disabled}
                      error={error}
                      setDemographicSelection={setDemographicSelection}
                      toggleDemographicSelection={toggleDemographicSelection}
                    />
                  );
                }
              )}

              <Button
                loadingPosition={'end'}
                loading={loading}
                type={'submit'}
                size={'lg'}
                onClick={onClick}
                data-component={loading ? 'demographics-page-button-loading' : 'demographics-page-button'}
              >
                {t('Next', { ns: 'translation' })}
              </Button>
            </Stack>
          </form>
        </Stack>
      </MainWrapper>
    </>
  );
};
